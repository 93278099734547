import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnagraficaFetchParams } from '../types';
import { handleError, URL_ANAGRAFICA, URL_GET, URL_V1 } from '../../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../../utils';

export const fetchAnagrafica = createAsyncThunk(
  'anagrafica/fetch',
  async ({ id, ...params }: AnagraficaFetchParams, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_GET}/${id}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
