import { FormInput, SelectForm } from 'components';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const Note = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Note Cliente</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <FormInput
              type="textarea"
              name="nota"
              label="Note"
              placeholder="Note"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              rows={5}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Note;
