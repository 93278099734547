import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, URL_GEO, URL_GEO_CITTA, URL_ITEMS } from '../../../utils';

export const fetchCap = createAsyncThunk(
  'cap/fetch',
  async ({ cap }: { cap: string }, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_GEO}${URL_GEO_CITTA}${URL_ITEMS}/${cap}`,
        params: {},
      });
      if (response?.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
