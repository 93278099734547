import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDisclosure, useWindowSize } from 'hooks';
import {
  FiltroChiave,
  getSelectorSelectedIds,
  TabsId,
  TYPE_NUMBER,
  TYPE_INTEGER,
  TYPE_CEDOLINI,
  TYPE_CURRENCY,
  TYPE_STRING,
} from 'store';
import { MenuItem } from 'utils/types';
import { ModuleName } from 'utils/constants';
import { Pagination } from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { useGrid } from './hook';
import { Header } from './Header';
import IndeterminateCheckbox from './IndeterminateCheck';
import { Th } from './Th';
import { ColumnCustomHeader } from 'utils/constants/tables';
import { Box, Dialog, FiltroForm, Flex } from 'components';
import TablePagination from 'components/TablePagination';
import './style.css';

interface TableProps {
  tabId: TabsId;
  title: string;
  selectedId: number;
  menu?: MenuItem[];
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  hasCreateFilter?: boolean;
  module?: ModuleName;
  selectRow?: (id: number) => void;
  open?: (isOpen: boolean) => void;
  handleOpenNew?: () => void;
  handleOpenImport?: () => void;
  hasSelectorTime?: boolean;
  hasImport?: boolean;
  hasExport?: boolean;
  hasNew?: boolean;
}

const THEAD = 89;
const TR = 33;
const HEADER = 70;
const NAVBAR = 55;
const OPTION = 58;
const PAGINATION = 48;

export default function StickyHeadTable(props: TableProps) {
  const [page, setPage] = React.useState(1);
  const [tableHeight, settableHeight] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [limitRaw, setLimitRaw] = React.useState(0);
  const [filters, setFilters] = React.useState<FiltroChiave[]>([]);
  const [columnSort, setColumnSort] = React.useState<string | null>(null);

  const selectedIds = getSelectorSelectedIds(props.tabId);

  const {
    isOpen: isOpenCreateFiltri,
    onOpen: handleOpenFiltri,
    onClose: handleCloseFiltri,
  } = useDisclosure();

  const { data, params, loading, updateParams, columnSettings, rowSelection } = useGrid({
    tabId: props.tabId,
    initialParams: { skip: 0, limit: limitRaw },
  });

  const onChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    console.log({ newPage, page });
    setPage(newPage);
    const newSkip = newPage * params.limit;
    console.log('onChange newskip', { newSkip });
    updateParams({ skip: (newPage - 1) * params.limit });
  };

  const { height } = useWindowSize();

  const tableRef = React.useRef<HTMLTableElement>(null);

  React.useEffect(() => {
    if (height) {
      const tbodyHeight =
        height - HEADER - NAVBAR - OPTION - THEAD - (props.pagination ? PAGINATION : 0);
      if (tbodyHeight > 0) {
        const limit = tbodyHeight / TR;
        settableHeight(tbodyHeight + THEAD);
        setLimitRaw(Math.round(limit));
      }
    }
  }, [height]);

  React.useEffect(() => {
    limitRaw && updateParams({ limit: limitRaw });
  }, [limitRaw]);
  return (
    <Box
      display="grid"
      grid-template-rows="40px auto 35px"
      flexDirection="column"
      justifyContent={'space-between'}
      ref={tableRef}
      position="relative"
      height="100vh"
    >
      <Header
        title={props.title}
        menu={props.menu}
        tabId={props.tabId}
        params={params}
        updateParams={updateParams}
        handleOpenNew={props.handleOpenNew}
        handleOpenImport={props.handleOpenImport}
        handleOpenFiltri={() => {
          handleOpenFiltri();
        }}
        hasCreateFilter={props.hasCreateFilter}
        filters={filters}
        hasSelectorTime={!!props.hasSelectorTime}
        hasNew={props.hasNew}
        hasImport={props.hasImport}
        hasExport={props.hasExport}
      />
      <TableContainer sx={{ position: 'relative', maxHeight: tableHeight }}>
        <Table stickyHeader aria-label="sticky table" size="small" width={'100%'}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <IndeterminateCheckbox tabId={props.tabId} header />
              </TableCell>
              {columnSettings.map((column: any, indexColumn: any) => (
                <Th
                  column={column}
                  tabId={props.tabId}
                  params={params}
                  updateParams={updateParams}
                  filters={filters}
                  setFilters={setFilters}
                  columnSort={columnSort}
                  setColumnSort={setColumnSort}
                  key={`grid-col-${indexColumn}`}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell padding="checkbox">
                    <IndeterminateCheckbox tabId={props.tabId} id={row?.id} />
                  </TableCell>
                  {columnSettings.map((column: ColumnCustomHeader) => {
                    const value = row[column.accessor];
                    return (
                      <TableCell
                        key={column.id}
                        className={
                          row.id === props.selectedId ? `tr-soft-primary` : `hover-soft-primary`
                        }
                        onDoubleClick={() => {
                          props?.selectRow && props.selectRow(row.id);
                          props?.open && props.open(true);
                        }}
                        align={
                          [TYPE_NUMBER, TYPE_INTEGER, TYPE_CEDOLINI, TYPE_CURRENCY].includes(
                            column.type ?? TYPE_STRING
                          )
                            ? 'right'
                            : 'left'
                        }
                      >
                        <div className="textContainer">
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box position="absolute" bottom="9rem" height="35px" width="100%">
        {props?.pagination && (
          <TablePagination
            tabId={props.tabId}
            params={params}
            updateParams={updateParams}
            initialLimit={limitRaw}
          />
        )}
      </Box>
      {props.hasCreateFilter && (
        <Dialog
          title="Creazione Filtro"
          show={isOpenCreateFiltri}
          fullscreen="sm-down"
          setShow={handleOpenFiltri}
        >
          <FiltroForm
            id={0}
            module={props.module}
            handleClose={handleCloseFiltri}
            filters={filters}
            selectedIds={selectedIds}
          />
        </Dialog>
      )}
    </Box>
  );
}
