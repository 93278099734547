import { createSelector } from 'reselect';
import { Anagrafica } from '../..';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getAnagraficheState = (state: RootState) => state.anagrafiche;

export const getAnagraficheLoader = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loading === PENDING
);

export const getAnagraficheFailed = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loading === FAILED
);

export const getAnagraficheSuccess = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loading === SUCCEEDED
);

export const getAnagraficheOptionLoader = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loadingOption === PENDING
);

export const getAnagraficheOptionFailed = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loadingOption === FAILED
);

export const getAnagraficheOptionSuccess = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loadingOption === SUCCEEDED
);

export const getAnagraficheEnumLoader = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loadingEnum === PENDING
);

export const getAnagraficheEnumFailed = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loadingEnum === FAILED
);

export const getAnagraficheEnumSuccess = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState.loadingEnum === SUCCEEDED
);

export const getAnagraficheError = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.error
);

export const getAnagrafiche = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.data
);

export const getAnagraficheInfo = createSelector(
  getAnagraficheState,
  (anagraficheState): Info => anagraficheState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getAnagraficheOption = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.dataOption
);

export const getAnagraficheOptionInfo = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.infoOption
);

export const getCategorieAnagrafica = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.categorie
);

export const getCategoriaAnagrafica = (gruppo: string | number) =>
  createSelector(getCategorieAnagrafica, (categorie) => {
    const gruppoElement = categorie
      ? categorie.find((element: Record<string, any>) => element.id === gruppo)
      : null;
    return gruppoElement;
  });

export const getAnagraficheTipologie = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.tipologie
);

export const getAnagraficheKeyValues = createSelector(getAnagrafiche, (anagrafiche) => anagrafiche?.map((anagrafica: Anagrafica) => ({ id: anagrafica.id, descrizione: anagrafica.ragione_sociale })) ?? []);
export const getAnagraficheOptionKeyValues = createSelector(getAnagraficheOption, (anagrafiche) => anagrafiche?.map((anagrafica: Anagrafica) => ({ id: anagrafica.id, descrizione: anagrafica.ragione_sociale })) ?? []);

export const getAnagraficaById = (id: string | number) =>
  createSelector(getAnagrafiche, (anagrafiche) =>
    anagrafiche?.find((anagraficaElement) => anagraficaElement.id === id)
  );

export const getAnagraficaOptionById = (id: string | number) =>
  createSelector(getAnagraficheOption, (anagrafiche) =>
    anagrafiche?.find((anagraficaElement) => anagraficaElement.id === id)
  );

export const getAnagraficaTipologia = (tipologia: string | number) =>
  createSelector(getAnagraficheTipologie, (tipologie) => {
    const tipologiaElement = tipologie
      ? tipologie.find((element: Record<string, any>) => element.id === tipologia)
      : null;
    return tipologiaElement;
  });

export const getAnagraficheParams = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.params
);

export const getAnagraficheParamsOption = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.paramsOption
);

export const getAnagraficheSelectedIds = createSelector(
  getAnagraficheState,
  (anagraficheState) => anagraficheState?.selectedIds
);
