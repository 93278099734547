import { MODULO_RIGHE } from './modules';
import {
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_ID,
  TabsId,
  MODULO_RIGHE_ID,
  SCHEDA_ID,
  schedeSlice,
  REFERENTE_ID,
} from '../../store';
import {
  anagrafiche,
  ColumnCustomHeader,
  filtri,
  moduli,
  moduloChiavi,
  referente,
  schede,
} from './tables';
import { GridColumns } from '@mui/x-data-grid-pro';

interface AccessorGrid {
  tabId: TabsId;
  delete: (id: any) => void;
  add: (parentId: any, position?: number, row?: any) => void;
}

export const accessorGrid = (params: AccessorGrid): GridColumns => {
  const { tabId, delete: handleDelete, add: handleNew } = params;
  const columnModuloChiavi = moduloChiavi(handleDelete, handleNew);
  const columnReferentre = referente(handleDelete);
  const map: Record<string, any> = {
    [MODULO_RIGHE_ID]: columnModuloChiavi,
    [REFERENTE_ID]: columnReferentre,
  };
  return map[tabId];
};
