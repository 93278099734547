import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
interface ConfirmModalProps {
  show: boolean;
  title: string;
  children: React.ReactElement | React.ReactElement[];
  maxWidth?: Breakpoint;
  onConfirm: (params?: any) => void;
  setShow: (show: boolean) => void;
}

export const ConfrimModal = ({
  title,
  children,
  setShow,
  onConfirm,
  show,
  maxWidth = 'sm',
}: ConfirmModalProps) => {
  return (
    <Dialog fullWidth open={show} onClose={() => setShow(false)} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => setShow(false)} variant="outlined" color="error">
          Annulla
        </Button>
        <Button onClick={onConfirm} variant="contained" className="btn btn-primary">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};
