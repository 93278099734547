import { GridActionsCellItem, GridColumns, GridPreProcessEditCellProps } from '@mui/x-data-grid';
import { ConfirmDelegate, Flex, ModuloChiavi } from 'components';
import React from 'react';
import {
  createModuloChiave,
  fetchModuloChiaviOptions,
  TipologiaChiave,
  TYPE_SELECT,
  useAppDispatch,
} from 'store';
import DeleteIcon from '@mui/icons-material/Delete';
import SecurityIcon from '@mui/icons-material/Security';
import AddIcon from '@mui/icons-material/AddCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InputTags from 'components/InputTag';
import TagInput from 'components/TagInputForm';
import { Button } from '@mui/material';

const tipologie: { value: string; label: string }[] = TipologiaChiave.map((tipologia) => ({
  value: tipologia.id as string,
  label: tipologia.descrizione as string,
}));

export const moduloChiavi = (
  handleDelete: (id: any) => void,
  handleNew: (parentId: any, position?: number, row?: any) => void
): GridColumns => {
  return [
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      width: 100,
      sortable: false,
      filterable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDelete(params.row.id)}
        />,
        <GridActionsCellItem
          icon={<AddIcon color="primary" />}
          label="Toggle Admin"
          onClick={() => handleNew(params.row.modulo_id, Number(params.row.posizione))}
        />,
      ],
    },
    { field: 'nome', headerName: 'Nome', width: 180, editable: true },
    {
      field: 'tipo',
      headerName: 'Tipo',
      type: 'singleSelect',
      editable: true,
      width: 180,
      filterable: false,
      valueOptions: () => tipologie,
      sortable: false,
      valueFormatter: ({ id: rowId, value, field, api }) =>
        tipologie.find((tipologia) => tipologia.value === value)?.label,
    },
    {
      field: 'elenco_valori',
      headerName: 'Elenco Valori',
      width: 220,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        console.log('render params', { params });
        const type = params.row.tipo;
        if (type === TYPE_SELECT) {
          return <ModuloChiavi moduloChiave={params.row} />;
        }
        return <Flex>-</Flex>;
      },
    },
  ];
};
