import React, { InputHTMLAttributes, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import classNames from 'classnames';

import { groupByFields } from '../../utils';

// images
import Avatar2 from '../assets/images/users/avatar-2.jpg';
import Avatar5 from '../assets/images/users/avatar-5.jpg';
import {
  FETCHLIST,
  getAction,
  getSelectorOptions,
  KeyValue,
  SETPARAMS,
  TabsId,
  useAppDispatch,
} from 'store';
import { ActionTypes } from '@mui/base';
import { useFormContext } from 'react-hook-form';
import { Option } from 'utils/types';
import { Form } from 'react-bootstrap';
import FormInput from 'components/FormInput';

interface SearchOptions {
  label: string;
  icon?: string;
  type: string;
  value?: string;
  userDetails?: {
    firstname: string;
    lastname: string;
    position: string;
    avatar: string;
  };
}

/*
 * get options
 */

/*
 * filter options
 */
const formateOptions = (options: SearchOptions[]) => {
  const grouppedData = groupByFields(options, (item: SearchOptions) => {
    return [item.type];
  });

  let formattedOptions = [];
  let count = 0;

  for (let i = 0; i < grouppedData.length; i++) {
    for (let j = 0; j < grouppedData[i].length; j++) {
      if (grouppedData[i][j].type === 'users' && count === 0) {
        grouppedData[i].splice(j, 0, {
          label: 'Users',
          value: 'title',
          type: 'title',
        });
        count = 1;
      }
      formattedOptions.push(grouppedData[i][j]);
    }
  }
  return formattedOptions;
};

/* custon indicator */
const IndicatorsContainer = (props: any) => {
  const hasValue = props.hasValue;
  const { name, handleClick } = props.selectProps;
  const { setValue } = useFormContext();
  const handleDelete = (ev: any) => {
    ev.preventDefault();
    setValue(name, null);
    props.selectProps.clearValue();
  };
  return (
    <div style={{}}>
      <components.IndicatorsContainer {...props}>
        <button
          className="btn input-group-text"
          onMouseDown={(ev: any) => (hasValue ? ev.preventDefault() : handleClick(ev))}
          onClick={hasValue ? handleDelete : handleClick}
        >
          <i className={`uil uil-${hasValue ? 'times' : 'search'}`}></i>
        </button>
      </components.IndicatorsContainer>
    </div>
  );
};

/* custom menu list */
const MenuList = (props: any) => {
  const { options } = props.selectProps;

  return (
    <components.MenuList {...props}>
      {/* menu header */}
      {options.length === 0 && (
        <div className="dropdown-header noti-title">
          <h5 className="text-overflow mb-2">Nessun dato presente</h5>
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

const Input = (props: any) => {
  return <FormInput {...props.selectProps} />;
};

interface SearchResultItem {
  id: number;
  title: string;
  redirectTo: string;
  icon: string;
}

interface SelectSearchFormProps extends InputHTMLAttributes<HTMLInputElement> {
  tabId: TabsId;
  label?: string;
  minHeight?: string;
  register: any;
  refCallback?: any;
  errors?: any;
  hasEmptyOption?: boolean;
  labelEmptyOption?: string;
  name: string;
}

const SelectSearchForm = ({
  tabId,
  label,
  register,
  refCallback,
  errors,
  minHeight = '.25rem',
  name,
  hasEmptyOption = false,
  labelEmptyOption,
  ...rest
}: SelectSearchFormProps) => {
  const elements: KeyValue[] = getSelectorOptions(tabId, true);
  const [options, setOptions] = React.useState<any[]>([]);

  const dispatch = useAppDispatch();

  const { setValue } = useFormContext();

  const [optionValue, setOptionValue] = React.useState<Option | null>(null);

  useEffect(() => {
    if (!elements || elements.length === 0) {
      const actionParams = getAction(tabId, SETPARAMS, true);
      if (actionParams) {
        dispatch(actionParams({ limit: 0, skip: 0 }));
      }
      const action = getAction(tabId, FETCHLIST, true);
      dispatch(action());
    }
  }, []);

  useLayoutEffect(() => {
    const newOptions = [];
    if (hasEmptyOption && labelEmptyOption) {
      newOptions.push({
        value: null,
        label: labelEmptyOption,
      });
    }
    if (elements?.length) {
      setOptions([
        ...newOptions,
        ...elements.map((element: KeyValue) => ({
          value: element.id as string,
          label: element.descrizione as string,
        })),
      ]);
    } else {
      setOptions(newOptions);
    }
  }, [elements?.length]);
  //TODO handleFormatOptionLabel come const function ed effettua il setValue di un option {value, label}

  const handleClickOption = (option: Option) => {
    setValue(
      name,
      elements.find((element) => String(option.value) === String(element.id))
    );
    setOptionValue({ value: option.value as string, label: option.label as string });
  };

  const optionGetter = (option: Option) => {
    return (
      <div
        className={classNames('dropdown-item', 'notify-item', 'p-0')}
        onClick={() => handleClickOption(option)}
      >
        {/* <i className={classNames(option.icon, 'me-1')}></i> */}
        <span>{option.label}</span>
      </div>
    );
  };

  const handleFormatOptionLabel = (option: Option) => {
    const formattedOption = optionGetter(option);
    return <div>{formattedOption}</div>;
  };

  return (
    <Form.Group style={{ minHeight }}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Select
        {...rest}
        components={{ IndicatorsContainer, MenuList }}
        options={options}
        isOptionSelected={!!optionValue}
        formatOptionLabel={handleFormatOptionLabel}
        isOptionDisabled={(option: any) => option.type === 'title'}
        ref={(r: HTMLInputElement) => {
          if (refCallback) refCallback(r);
        }}
        isInvalid={errors && errors[name] ? true : false}
        {...(register ? register(name) : {})}
        maxMenuHeight={350}
        clearValue={() => setOptionValue(null)}
        isSearchable
        isClearable
        name={name}
        className="app-search"
        classNamePrefix="react-select"
        value={optionValue}
      />
      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors[name]['message']}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default SelectSearchForm;
