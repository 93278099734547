import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setAnagraficaParams } from '.';
import { apiClient, handleError, RootState } from '../../..';
import { URL_ANAGRAFICA, URL_LIST } from '../../..';

export const fetchAnagrafiche = createAsyncThunk(
  'anagrafiche/fetch',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = state?.anagrafiche?.params ?? {};
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_LIST}`,
        params,
      });
      if (response.status === 200) {
        const info = response.data?.data?.info;
        if (info) {
          const currentPage = info.currentPage;
          const paramsPage = params.skip / params.limit + 1;
          currentPage < paramsPage &&
            thunkAPI.dispatch(setAnagraficaParams({ ...params, skip: 0 }));
        }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
