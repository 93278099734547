import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import {
  checkTokenValidity,
  fetchUser,
  getIsAuthenticated,
  getUserLoader,
  useAppDispatch,
} from '../../store';

const useInit = (): void => {
  const dispatch = useAppDispatch();

  const userLoader = useSelector(getUserLoader);

  const fetchData = () => {
    dispatch(fetchUser());
  };

  const isLogged = async () => {
    const token = await checkTokenValidity();
    return token !== null;
  };
  const loader = userLoader || isLogged();

  useEffect(() => {
    isLogged().then((result: boolean) => {
      if (result) {
        fetchData();
      }
    });
  }, []);
};

export default useInit;
