import { TEXT, DATE, SELECT, NUMBER } from 'components/Grid/Filters/model';
import { Months } from 'utils/date';
import { ColumnCustomHeader } from './model';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Nome',
    accessor: 'nome',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Mese',
    accessor: 'mese',
    sort: true,
    filterType: SELECT,
    options: Months,

  },
  {
    Header: 'Anno',
    accessor: 'anno',
    sort: true,
    filterType: NUMBER
  },
];

export const schede = {
  preview: baseColumns,
  complete: baseColumns,
};
