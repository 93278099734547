import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Anagrafica } from 'store';
import { ModelFormProps } from '../model';
import DatiBancari from './DatiBancari';
import DatiContatto from './DatiContatto';
import DatiFatturazione from './DatiFatturazione';
import DatiGenerali from './DatiGenerali';
import DatiReferenti from './DatiReferenti';
import Note from './Note';
import SedeLegale from './SedeLegale';

export const AnagraficaForm = ({ id, element }: ModelFormProps<Anagrafica>) => {
  const [activeKey, setActiveKey] = useState<string>('dati_anagrafici');
  const { trigger } = useFormContext();
  return (
    <Tabs
      id="noanim-tab-example"
      defaultActiveKey="dati_anagrafici"
      activeKey={activeKey}
      onSelect={async (eventKey: any, ev: any) => {
        const noErrors = await trigger();
        noErrors && setActiveKey(eventKey);
      }}
    >
      <Tab eventKey="dati_anagrafici" title="Azienda">
        <div>
          <DatiGenerali />
          <DatiContatto />
          <SedeLegale />
        </div>
      </Tab>
      <Tab eventKey="dati:referenti" title="Referenti">
        <DatiReferenti />
      </Tab>
      <Tab eventKey="dati_fiscali" title="Fatturazione">
        <DatiFatturazione />
      </Tab>
      <Tab eventKey="dati_bancari" title="Dati bancari">
        <DatiBancari />
      </Tab>
      <Tab eventKey="note" title="Note">
        <Note />
      </Tab>
    </Tabs>
  );
};

export default AnagraficaForm;
