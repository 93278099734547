import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setSchedaParams } from '.';
import { apiClient, handleError, RootState } from '../../..';
import { URL_SCHEDA, URL_LIST } from '../../..';

export const fetchSchede = createAsyncThunk('schede/fetch', async (_, thunkAPI): Promise<any> => {
  try {
    const state = thunkAPI?.getState() as RootState;
    const params = state?.schede?.params ?? {};
    const response = await apiClient.get<AxiosResponse>({
      url: `${URL_SCHEDA}${URL_LIST}`,
      params,
    });
    if (response.status === 200) {
      const info = response.data?.data?.info;
      if (info) {
        const currentPage = info.currentPage;
        const paramsPage = params.skip / params.limit + 1;
        currentPage < paramsPage && thunkAPI.dispatch(setSchedaParams({ ...params, skip: 0 }));
      }
      return response.data;
    }
    return handleError(response.status, thunkAPI, response.data?.status);
  } catch (error: any) {
    return handleError(error?.response?.status, thunkAPI);
  }
});
