import { RootState } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModuloChiaveDeleteParams } from '../types';
import { apiClient, handleError, URL_MODULO, URL_DELETE, URL_MODULO_RIGHE } from '../../../index';
import { AxiosResponse } from 'axios';
import { setModuloChiaviSelectedIds } from 'store/moduli';

export const deleteModuloChiave = createAsyncThunk(
  'moduloChiave/delete',
  async (params: ModuloChiaveDeleteParams, thunkAPI) => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.delete<AxiosResponse>({
        url: `${URL_MODULO}${URL_MODULO_RIGHE}${URL_DELETE}/${params.id}`,
      });
      if (response.status === 200) {
        if (state?.moduloChiavi?.selectedIds.includes(params.id)) {
          thunkAPI.dispatch(setModuloChiaviSelectedIds(params.id));
        }
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
