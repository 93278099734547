export const USER = 'user';
export const GEO = 'geo';
export const NAZIONE = 'nazione';
export const PROVINCIA = 'provincia';
export const REGIONE = 'regione';
export const COMUNE = 'comune';
export const CITTA = 'citta';
export const GEO_CAP = 'geo_cap';
export const EMAIL = 'email';
export const ATTACHMENT = 'attachment';
export const ANAGRAFICA = 'anagrafica';
export const FILTRO = 'filtro';
export const FILTRO_CHIAVI = 'filtro_chiavi';
export const IMPORTAZIONE = 'importazione';
export const MODULO = 'modulo';
export const MODULO_RIGHE = 'modulo_righe';
export const SCHEDA = 'scheda';
export const REFERENTE = 'referente';

export type ModuleName =
  | typeof USER
  | typeof GEO
  | typeof NAZIONE
  | typeof PROVINCIA
  | typeof REGIONE
  | typeof COMUNE
  | typeof CITTA
  | typeof GEO_CAP
  | typeof EMAIL
  | typeof ATTACHMENT
  | typeof ANAGRAFICA
  | typeof IMPORTAZIONE
  | typeof FILTRO
  | typeof FILTRO_CHIAVI
  | typeof MODULO
  | typeof MODULO_RIGHE
  | typeof SCHEDA
  | typeof REFERENTE;
