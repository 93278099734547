import * as dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DRIVE_TEST_ID, getAction, SETPARAMS, TabsId } from 'store';
import { MONTHS } from 'utils';
import FormInput from './FormInput';

interface SelectorTimeProps {
  tabId: TabsId;
  params: any;
  updateParams: (params: any) => void;
}

export const SelectorTime = ({ tabId, params, updateParams }: SelectorTimeProps) => {
  const dispatch = useDispatch();
  const dayjsElement = dayjs.default();

  const currentMonth = dayjsElement.month();
  const currentYear = dayjsElement.year();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const decrementMonth = () => {
    if (month > 0) {
      setMonth(month - 1);
    }
  };

  const incrementMonth = () => {
    if (month < 11) {
      setMonth(month + 1);
    }
  };

  const currentPeriod = () => {
    setMonth(currentMonth);
    setYear(currentYear);
  };

  useEffect(() => {
    if (tabId !== DRIVE_TEST_ID) {
      updateParams({ ...params, mese: month + 1, anno: year });
    }
  }, [month, year]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem',
      }}
    >
      <button
        type="button"
        className="cursor-pointer rounded-pill dropdown-toggle btn btn-soft-info"
        style={{ bottom: 0 }}
        onClick={decrementMonth}
        disabled={month === 0}
      >
        <i className="bi bi-caret-left-fill" style={{ fontSize: '.95rem' }}></i>
      </button>
      <Form.Group className="m-1">
        <select
          className={'form-select d-inline-block w-auto'}
          name="month"
          value={month}
          onChange={(ev: ChangeEvent<HTMLSelectElement>) => {
            ev?.target?.value && setMonth(Number(ev?.target?.value));
          }}
        >
          {MONTHS?.map((monthDescription, index) => (
            <option key={index} value={index}>
              {monthDescription}
            </option>
          ))}
        </select>
      </Form.Group>
      <FormInput
        type="number"
        value={year}
        name="year"
        containerClass="m-1"
        style={{ width: '8rem' }}
        min={currentYear - 30}
        max={currentYear + 30}
        onChange={(ev: any) => {
          ev?.target?.value && setYear(Number(ev?.target?.value));
        }}
      />
      <button
        type="button"
        className="cursor-pointer rounded-pill dropdown-toggle btn btn-soft-info"
        style={{ bottom: 0 }}
        onClick={incrementMonth}
        disabled={month === 11}
      >
        <i className="bi bi-caret-right-fill" style={{ fontSize: '.95rem' }}></i>
      </button>
      <button
        type="button"
        className="cursor-pointer btn btn-sm btn-soft-primary m-1"
        onClick={currentPeriod}
      >
        Periodo corrente
      </button>
    </div>
  );
};

export default SelectorTime;
