import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RegisterRequest } from '../types';
import { apiClient, handleError } from '../../utils';

import { URL_USER, URL_CREATE } from '../../utils/constants';

export const registerUser = createAsyncThunk(
  'auth/register',
  async (params: RegisterRequest, thunkAPI) => {
    try {
      const { confirmPassword, ...request } = params;
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_USER}${URL_CREATE}`,
        params: request,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
