import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface AlertProps extends SweetAlertOptions {
  isOpen?: boolean;
}

export const AlertMessage = ({ isOpen, ...rest }: AlertProps) => {
  const Alert = withReactContent(Swal);
  return Alert.fire({ ...rest });
};
