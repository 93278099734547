import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Filtro } from 'store';
import { ModelFormProps } from '../model';
import DatiGenerali from './DatiGenerail';
import ElencoFiltri from './ElencoFiltri';

export const FiltroEditForm = ({ id, element }: ModelFormProps<Filtro>) => {
  return (
    <>
      <DatiGenerali />
      <ElencoFiltri />
    </>
  );
};

export default FiltroEditForm;
