export const LOGIN_ID = 'LOGIN';
export const REGISTER_ID = 'REGISTER';
export const CONFIRM_ID = 'CONFIRM';
export const ANAGRAFICA_ID = 'ANAGRAFICA';
export const FILTRO_ID = 'FILTRO';
export const MODULO_ID = 'MODULO';
export const MODULO_RIGHE_ID = 'MODULO_RIGHE';
export const ANAGRAFICA_CATEGORIA_ID = 'ANAGRAFICA_CATEGORIA';
export const ANAGRAFICA_TIPOLOGIA_ID = 'ANAGRAFICA_TIPOLOGIA';
export const DRIVE_TEST_ID = 'DRIVE_TEST';
export const SCHEDA_ID = 'SCHEDA';
export const REFERENTE_ID = 'REFERENTE';
export const TABS_IDS = [
  LOGIN_ID,
  REGISTER_ID,
  CONFIRM_ID,
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_RIGHE_ID,
  DRIVE_TEST_ID,
  SCHEDA_ID,
  REFERENTE_ID,
];

export type TabsId = typeof TABS_IDS[number];

export const EnumsId = {
  ANAGRAFICA_CATEGORIA_ID,
  ANAGRAFICA_TIPOLOGIA_ID,
};
