import { RootState } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FiltroDeleteParams } from '../types';
import { apiClient, handleError, URL_FILTRO, URL_DELETE, URL_V1 } from '../../../index';
import { AxiosResponse } from 'axios';
import { setFiltroSelectedIds } from 'store/filtri';

export const deleteFiltro = createAsyncThunk(
  'filtro/delete',
  async (params: FiltroDeleteParams, thunkAPI) => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.delete<AxiosResponse>({
        url: `${URL_FILTRO}${URL_DELETE}/${params.id}`,
      });
      if (response.status === 200) {
        if (state?.filtri?.selectedIds.includes(params.id)) {
          thunkAPI.dispatch(setFiltroSelectedIds(params.id));
        }
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
