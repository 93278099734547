import { Referente } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { createReferente, deleteReferente, fetchReferente, resetReferente } from '../actions';
import { FAILED, IDLE, PENDING, StateSingular, SUCCEEDED } from '../../../../types';

const initialState: StateSingular<Referente> = {
  data: null,
  loading: IDLE,
  error: null,
};

export const referenteSlice = createSlice({
  name: 'referente',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferente.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchReferente.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchReferente.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(createReferente.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(createReferente.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(createReferente.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(resetReferente, (state) => {
        state.data = null;
        state.loading = IDLE;
        state.error = null;
      })
      .addCase(deleteReferente.fulfilled, (state) => {
        state.data = null;
        state.loading = SUCCEEDED;
      })
      .addCase(deleteReferente.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(deleteReferente.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      });
  },
});
