import { MODULO, SCHEDA } from './../../utils/constants/modules';
import { useSelector } from 'react-redux';
import { LoginSelector } from '../auth/types/selectors';
import { AnagraficaMapSelector } from '../anagrafiche/anagrafica/types';
import { AnagraficheMapSelector } from '../anagrafiche/anagrafiche/types';
import { selectorTypes } from '../utils/constants/selectorTypes';
import { getUser } from '../user';
import { REFERENTE_ID, TabsId, TABS_IDS } from 'store/utils';
import { Info, KeyValue } from '.';
import { FiltriMapSelector, FiltroMapSelector } from 'store/filtri';
import {
  ModuliMapSelector,
  ModuloMapSelector,
  ModuloChiaveMapSelector,
  ModuloChiaviMapSelector,
} from 'store/moduli';
import { SchedaMapSelector, SchedeMapSelector } from 'store/schede';
import { ReferenteMapSelector, ReferentiMapSelector } from 'store/anagrafiche';

const mapSelector: Record<any, any> = {
  LOGIN: { ...LoginSelector },
  // REGISTER: {
  //   loading: getAuthLoader,
  //   success: registeredSuccess,
  //   data: getUser,
  //   params: getUser,
  //   info: getUser,
  // },
  // CONFIRM: {
  //   loading: getAuthLoader,
  //   success: getAuthSuccess,
  //   data: getUser,
  //   params: getUser,
  //   info: getUser,
  // },
  ANAGRAFICA: { ...AnagraficaMapSelector, ...AnagraficheMapSelector },
  FILTRO: { ...FiltroMapSelector, ...FiltriMapSelector },
  MODULO: { ...ModuloMapSelector, ...ModuliMapSelector },
  MODULO_RIGHE: { ...ModuloChiaveMapSelector, ...ModuloChiaviMapSelector },
  SCHEDA: { ...SchedaMapSelector, ...SchedeMapSelector },
  REFERENTE: { ...ReferenteMapSelector, ...ReferentiMapSelector },
  DRIVE_TEST: {},
};

export const getSelectorLoading = (tabId: TabsId, options = false): boolean =>
  useSelector(mapSelector[tabId][options ? selectorTypes.loadingOption : selectorTypes.loading]);
export const getSelectorSuccess = (tabId: TabsId, options = false) =>
  useSelector(mapSelector[tabId][options ? selectorTypes.successOption : selectorTypes.success]);
export const getSelectorListData = (tabId: TabsId, options = false) =>
  useSelector(mapSelector[tabId][options ? selectorTypes.dataOption : selectorTypes.data]);
export const getSelectorListDataParams = (tabId: TabsId, options = false): Record<string, any> =>
  useSelector(mapSelector[tabId][options ? selectorTypes.paramsOption : selectorTypes.params]);
export const getSelectorListDataInfo = (tabId: TabsId, options = false): Info =>
  useSelector(mapSelector[tabId][options ? selectorTypes.infoOption : selectorTypes.info]);
export const getSelectorSelectedIds = (tabId: TabsId): any[] =>
  useSelector(mapSelector[tabId][selectorTypes.selectedIds]);

export const getSelectorLoadingElement = (tabId: TabsId): boolean =>
  useSelector(mapSelector[tabId][selectorTypes.loadingElement]);
export const getSelectorElement = <T>(tabId: TabsId): T => {
  return useSelector(mapSelector[tabId][selectorTypes.element]);
};
export const getSelectorElementById = <T>(tabId: TabsId, id: string | number, options = false): T =>
  useSelector(
    mapSelector[tabId][options ? selectorTypes.elementOptionById : selectorTypes.elementById](id)
  );

export const getSelectorOptions = (tabId: TabsId, options = false): KeyValue[] =>
  useSelector(
    mapSelector[tabId][
      options ? selectorTypes.selectOptionKeyValues : selectorTypes.selectKeyValues
    ]
  ) ?? [];
