import { RootState } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnagraficaDeleteParams } from '../types';
import { apiClient, handleError, URL_ANAGRAFICA, URL_DELETE, URL_V1 } from '../../../index';
import { AxiosResponse } from 'axios';
import { setAnagraficaSelectedIds } from 'store/anagrafiche';

export const deleteAnagrafica = createAsyncThunk(
  'anagrafica/delete',
  async (params: AnagraficaDeleteParams, thunkAPI) => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.delete<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_DELETE}/${params.id}`,
      });
      if (response.status === 200) {
        if (state?.anagrafiche?.selectedIds.includes(params.id)) {
          thunkAPI.dispatch(setAnagraficaSelectedIds(params.id));
        }
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
