import { KeyValue } from "store/types";

export interface Scheda {
  id?: number;
  nome: string | null;
  mese?: number;
  anno?: number;
  filtro: KeyValue | null;
  modulo: KeyValue | null;
  created?: Date;
  updated?: Date;
}

export class SchedaClass implements Scheda {
  id = 0;
  nome = null;
  mese = 0;
  anno = 0;
  filtro = null;
  modulo = null;
  created = undefined;
  updated = undefined;
}
