import Icon from '@ailibs/feather-react-ts';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { MenuItem } from 'utils/types';

interface DropdownMenuProps {
  menu: MenuItem[];
}

export const DropdownMenu = ({ menu }: DropdownMenuProps) => {
  return (
    <Dropdown className="d-inline-block" align="start">
      <Dropdown.Toggle variant="soft-primary" className="cursor-pointer rounded-pill">
        <i className="uil uil-ellipsis-v"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {menu.map(({ onClick, title, key, icon }: MenuItem, index) => {
          return (
            <Dropdown.Item key={key ?? `menu-${index}`} onClick={onClick}>
              {icon && (
                <Icon
                  name={icon}
                  size={14}
                  className={classNames('align-self-center, icon-xs me-1')}
                />
              )}
              <span> {title} </span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
