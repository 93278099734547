import { SchedaState } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { createScheda, deleteScheda, fetchScheda, resetScheda } from '../actions';
import { FAILED, IDLE, PENDING, SUCCEEDED } from '../../../types';

const initialState: SchedaState = {
  data: null,
  loading: IDLE,
  error: null,
  config: null,
  list: [],
};

export const schedaSlice = createSlice({
  name: 'scheda',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchScheda.fulfilled, (state: any, { payload }: any) => {
        state.data = payload?.data?.scheda;
        state.list = payload?.data?.list;
        state.config = payload?.data?.config;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        fetchScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(
        createScheda.fulfilled,
        (state: { data: any; list: never[]; config: null; loading: string }, { payload }: any) => {
          state.data = payload;
          state.list = [];
          state.config = null;
          state.loading = SUCCEEDED;
        }
      )
      .addCase(createScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        createScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(
        resetScheda,
        (state: { data: null; list: never[]; config: null; loading: string; error: null }) => {
          state.data = null;
          state.list = [];
          state.config = null;
          state.loading = IDLE;
          state.error = null;
        }
      )
      .addCase(
        deleteScheda.fulfilled,
        (state: { data: null; list: never[]; config: null; loading: string }) => {
          state.data = null;
          state.list = [];
          state.config = null;
          state.loading = SUCCEEDED;
        }
      )
      .addCase(deleteScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        deleteScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      );
  },
});
