import { combineReducers } from '@reduxjs/toolkit';
import { referenteSlice, referentiSlice } from './anagrafiche';
import { anagraficaSlice } from './anagrafiche/anagrafica/reducers';
import { anagraficheSlice } from './anagrafiche/anagrafiche/reducers';
import { authSlice } from './auth';
import { errorSlice } from './error';
import { filtriSlice, filtroSlice } from './filtri';
import { addressSlice } from './geo/cap';
import { cittaSlice } from './geo/citta';
import { nazioniSlice } from './geo/nazioni';
import { importazioneSlice } from './importazioni/importazione/reducers';
import { layoutSlice } from './layout/reducers';
import { moduliSlice, moduloChiaveSlice, moduloChiaviSlice, moduloSlice } from './moduli';
import { schedaSlice, schedeSlice } from './schede';
import { userSlice } from './user';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  cap: addressSlice.reducer,
  citta: cittaSlice.reducer,
  nazioni: nazioniSlice.reducer,
  user: userSlice.reducer,
  error: errorSlice.reducer,
  layout: layoutSlice.reducer,
  anagrafica: anagraficaSlice.reducer,
  anagrafiche: anagraficheSlice.reducer,
  importazione: importazioneSlice.reducer,
  filtro: filtroSlice.reducer,
  filtri: filtriSlice.reducer,
  modulo: moduloSlice.reducer,
  moduli: moduliSlice.reducer,
  moduloChiave: moduloChiaveSlice.reducer,
  moduloChiavi: moduloChiaviSlice.reducer,
  scheda: schedaSlice.reducer,
  schede: schedeSlice.reducer,
  referente: referenteSlice.reducer,
  referenti: referentiSlice.reducer,
});

export default rootReducer;
