import { Button } from '@mui/material';
import { ConfirmDelegate } from 'components';
import TagInput from 'components/TagInputForm';
import React from 'react';
import {
  createModuloChiave,
  fetchModuloChiaviOptions,
  ModuloChiave,
  ModuloChiaveCreateParams,
  MODULO_RIGHE_ID,
  useAppDispatch,
} from 'store';

interface ModuloChiaviElencoValoriProps {
  moduloChiave: any;
}

export const ModuloChiaviElencoValori = ({ moduloChiave }: ModuloChiaviElencoValoriProps) => {
  const [elencoTag, setElencoTag] = React.useState<string[]>([]);
  const dispatch = useAppDispatch();
  const saveTags = async () => {
    const request: Record<string, any> = {};
    const keys = Object.keys(moduloChiave);
    keys.forEach((key: string) => {
      if (key === 'elenco_valori') request['elenco_valori'] = elencoTag;
      else request[key] = moduloChiave[key];
    });
    console.log('save tags', { moduloChiave, request, elencoTag });
    const response = await dispatch(createModuloChiave(request as ModuloChiaveCreateParams));
    if (response) {
      await dispatch(fetchModuloChiaviOptions());
    }
    setElencoTag([]);
  };
  return (
    <ConfirmDelegate
      title={'Elenco Valori'}
      clickElement={
        <Button variant="text">
          {moduloChiave.elenco_valori?.length ? 'Visualizza/modifica' : 'Aggiungi'}
        </Button>
      }
      children={
        <TagInput
          name={'elenco_valori'}
          tags={moduloChiave.elenco_valori ?? []}
          onChangeTagList={(tags: string[]) => {
            console.log('onChangeTagList', { tags, chiavi: moduloChiave.elenco_valori, elencoTag });
            setElencoTag(tags);
          }}
        />
      }
      onConfirm={saveTags}
    />
  );
};

export default ModuloChiaviElencoValori;
