// base URLs
console.log({ env: process.env.REACT_APP_TEST_KEY });
export const BASE = 'https://api.paghe365.online/'; // process.env.REACT_APP_API_URL; //'http://localhost:8080/'; //

// sections
export const URL_USER = '/user';
export const URL_ANAGRAFICA = '/anagrafica';
export const URL_ANAGRAFICA_CONTATTI = '/anagrafica_contatto';
export const URL_ANAGRAFICA_EMAILS = '/anagrafica_email';
export const URL_REFERENTE = '/referente';
export const URL_GEO = '/geo';
export const URL_GEO_NAZIONE = '/nazione';
export const URL_GEO_PROVINCIA = '/provincia';
export const URL_GEO_REGIONE = '/regione';
export const URL_GEO_COMUNE = '/comune';
export const URL_GEO_CITTA = '/citta';
export const URL_GEO_CAP = '/geo_cap';
export const URL_ATTIVITA = '/attivita';
export const URL_CONTATTO_CLIENTE = '/contatto_cliente';
export const URL_POLIZZA = '/polizza';
export const URL_DEAL = '/deal';
export const URL_ESITO = '/esito';
export const URL_COMPAGNIA = '/compagnia';
export const URL_IMPORTAZIONE = '/importazione';
export const URL_FILTRO = '/filtro';
export const URL_MODULO = '/modulo';
export const URL_MODULO_RIGHE = '/modulo_righe';
export const URL_SETTING = '/setting';
export const URL_SCHEDA = '/scheda';
export const URL_LOCK = '/lock';

// versions
export const URL_V1 = '/v1';

// endpoints

// users endpoints
export const URL_EMAIL_CHANGE = '/email_change';
export const URL_EMAIL_CHANGE_VERIFY = '/email_change_verify';
export const URL_LOGIN = '/login';
export const URL_LOGOUT = '/logout';
export const URL_PASSWORD_CHANGE = '/password_change';
export const URL_PASSWORD_RESET = '/password_reset';
export const URL_REFRESH = '/refreshToken';
export const URL_ME = '/me';
export const URL_CONFIRM = '/confirm';
export const URL_FILE = '/file';

//impostazioni endpoints
export const URL_PREDEFINITI = '/predefiniti';

//utenti endpoints
export const URL_REGISTER = '/register';

//system endpoints
export const URL_VERIFICA = '/verifica';

// common endpoints
export const URL_CREATE = '/create';
export const URL_SAVE = '/save';
export const URL_CREATE_MULTI = '/create_multi';
export const URL_DELETE = '/delete';
export const URL_DELETE_EMPTY_ROWS = '/delete_empty_rows';
export const URL_DELETE_MULTI = '/delete_multi';
export const URL_DOWNLOAD = '/download';
export const URL_GET = '/item';
export const URL_ITEM = '/item';
export const URL_LIST = '/list';
export const URL_ITEMS = '/items';
export const URL_SELECT_ALL = '/selectAll';
export const URL_LOG = '/log';
export const URL_TIPOLOGIA = '/tipologia';
export const URL_STATO = '/stato';
export const URL_GRUPPO = '/gruppo';
export const URL_CATEGORIA = '/categoria';
export const URL_FRAZIONAMENTI = '/frazionamenti';
export const URL_CLASSIFICAZIONI = '/classificazioni';
export const URL_UPDATE = '/update';
export const URL_UPLOAD = '/upload';
export const URL_UTENTE = '/utente';
export const URL_CREATE_POSITION = '/create_position';
export const URL_REMOVE = '/remove';
