import { KeyValue } from 'store';

export const DATE_FORMAT = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  YYYY_MM_DD_HH_MM_ss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DDTHH_MM: 'YYYY-MM-DDTHH:mm',
  YYYY: 'YYYY',
  DD_MM_YYYY: 'DD/MM/YYYY',
  DD_MM_YYYY_HH_MM: 'DD/MM/YYYY HH:mm',
  DD_MM_YYYY__HH_MM: 'YYYY-MM-DD\xa0\xa0HH:mm:ss',
  DD_MM_YYYY_WITH_MINUS: 'DD-MM-YYYY',
};

export const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

export const Months: KeyValue[] = [
  { id: 0, descrizione: '' },
  { id: 1, descrizione: 'Gennaio' },
  { id: 2, descrizione: 'Febbraio' },
  { id: 3, descrizione: 'Marzo' },
  { id: 4, descrizione: 'Aprile' },
  { id: 5, descrizione: 'Maggio' },
  { id: 6, descrizione: 'Giugno' },
  { id: 7, descrizione: 'Luglio' },
  { id: 8, descrizione: 'Agosto' },
  { id: 9, descrizione: 'Settembre' },
  { id: 10, descrizione: 'Ottobre' },
  { id: 11, descrizione: 'Novembre' },
  { id: 12, descrizione: 'Dicembre' },
];
