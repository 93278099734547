import { TEXT, DATE } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Nome',
    accessor: 'nome',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Data Creazione',
    accessor: 'created',
    sort: true,
  },
];

export const moduli = {
  preview: baseColumns,
  complete: baseColumns,
};
