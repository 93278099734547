import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState, URL_MODULO_RIGHE, URL_SELECT_ALL } from '../../..';
import { URL_MODULO, URL_LIST } from '../../..';

export const selectModuloChiaviAll = createAsyncThunk(
  'moduloChiavi/selectAll',
  async (_, thunkAPI): Promise<any> => {
    const state = thunkAPI?.getState() as RootState;
    const params = state?.moduli?.params ?? {};
    const { parentId, ...rest } = params;
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_MODULO}${URL_MODULO_RIGHE}${URL_SELECT_ALL}/${parentId}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
