import Icon from '@ailibs/feather-react-ts';
import { Divider, InputBase, Paper, IconButton } from '@mui/material';
import { DropdownMenu, Flex, SelectorTime } from 'components';
import { ChangeEvent, useState } from 'react';
import { Row, Button, Dropdown } from 'react-bootstrap';
import { FiltroChiave, RangeDate, TabsId } from 'store';
import { MenuItem } from 'utils/types';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';

interface HeaderProps {
  tabId: TabsId;
  params: any;
  updateParams: (params: any) => void;
  handleOpenFiltri: () => void;
  title?: string;
  menu?: MenuItem[];
  hasNew?: boolean;
  hasImport?: boolean;
  hasExport?: boolean;
  handleOpenNew?: () => void;
  handleOpenImport?: () => void;
  hasCreateFilter?: boolean;
  filters: FiltroChiave[];
  hasSelectorTime: boolean;
}

const SEARCH_FIELD = 'qvalue';

export const Header = ({
  tabId,
  title,
  menu,
  params,
  hasNew = true,
  hasImport = true,
  hasExport = true,
  updateParams,
  handleOpenFiltri,
  handleOpenNew,
  handleOpenImport,
  hasCreateFilter = false,
  filters,
  hasSelectorTime,
}: HeaderProps) => {
  const [time, setTime] = useState<RangeDate | null>(null);

  const menuNew: MenuItem = {
    key: `new-${tabId}`,
    title: 'Aggiungi',
    onClick: handleOpenNew ?? (() => void 0),
    icon: 'plus-circle',
  };
  const menuImport: MenuItem = {
    key: `import-${tabId}`,
    title: 'Importa',
    onClick: handleOpenImport ?? (() => void 0),
    icon: 'upload-cloud',
  };

  const menuExport: MenuItem = {
    key: `export-${tabId}`,
    title: 'Esporta',
    onClick: () => void 0,
    icon: 'download-cloud',
  };
  const menuCreateFilter: MenuItem = {
    key: `create-filter-${tabId}`,
    title: 'Crea filtro',
    onClick: handleOpenFiltri,
    icon: 'filter',
  };
  const menuOptions: MenuItem[] = menu ?? [
    ...(hasNew ? [menuNew] : []),
    ...(hasImport ? [menuImport] : []),
    ...(hasExport ? [menuExport] : []),
    ...(hasCreateFilter && filters.length ? [menuCreateFilter] : []),
  ];
  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    if (!value.length) {
      const { ...otherParams } = params;
      otherParams[SEARCH_FIELD] = undefined;
      return updateParams({ ...otherParams });
    }
    value.length >= 3 && updateParams({ ...params, [SEARCH_FIELD]: value });
  };

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef?.current?.value) {
      const { ...otherParams } = params;
      inputRef.current.value = '';
      otherParams[SEARCH_FIELD] = undefined;
      return updateParams({ ...otherParams });
    }
  };

  const sizeMenu = menuOptions ? 4 : 0;
  const sizeSelectorTime = hasSelectorTime ? 4 : 0;
  const sizeClear = 12 - sizeMenu - sizeSelectorTime;

  return (
    <Flex justifyContent={'space-between'} width="100vw" height="3rem">
      <Flex>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {menuOptions && menuOptions.length > 0 && <DropdownMenu menu={menuOptions} />}
          <div className="header-title" style={{ paddingLeft: '1.5rem' }}>
            {title}
          </div>
        </div>
      </Flex>
      {hasSelectorTime && (
        <Flex>
          <SelectorTime tabId={tabId} params={params} updateParams={updateParams} />
        </Flex>
      )}

      <Flex justifyContent={'end'}>
        <Paper
          component="form"
          sx={{
            p: '0',
            display: 'flex',
            alignItems: 'center',
            width: 350,
            height: 39,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            inputRef={inputRef}
            onChange={onChange}
            placeholder="Ricerca generale"
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          {inputRef?.current?.value && (
            <React.Fragment>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={handleClick}
              >
                <ClearIcon />
              </IconButton>
            </React.Fragment>
          )}
        </Paper>
      </Flex>
    </Flex>
  );
};
