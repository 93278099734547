import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setReferenteSelectedIds } from '.';
import { apiClient, handleError, RootState, setError } from '../../../..';
import { URL_REFERENTE, URL_DELETE_MULTI } from '../../../..';

export const deleteReferentiMulti = createAsyncThunk(
  'referenti/fetch',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = {}; //state?.referenti?.selectedIds ?? [];
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_REFERENTE}${URL_DELETE_MULTI}`,
        params: { ids: params },
      });
      if (response.status === 200) {
        const deleted = response.data.data.deleted;
        const notDeleted = response.data.data.notDeleted;
        // if (deleted) {
        //   deleted.forEach((id) => {
        //     if (state?.referenti?.selectedIds.includes(id)) {
        //       thunkAPI.dispatch(setReferenteSelectedIds(id));
        //     }
        //   });
        // }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
