import * as React from 'react';
import { GridRowModel } from '@mui/x-data-grid';
import Flex from 'components/Flex';
import './style.css';
import { useEditableGrid } from './hook';
import FormContext from 'components/Form/FormContext';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { NoRowsOverlay } from './NoRowsOverlay';
import { TabsId, useAppDispatch } from 'store';
import { useFormContext } from 'react-hook-form';
import { ModuleName, reorder } from 'utils';
import { createPosition } from 'store/setting/actions/createPosition';
import { ConfrimModal } from 'components/ConfirmModal';
import { MenuItem } from 'utils/types';

export interface EditableGridProps {
  tabId: TabsId;
  title: string;
  selectedId: number;
  menu?: MenuItem[];
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  hasCreateFilter?: boolean;
  module: ModuleName;
  selectRow?: (id: number) => void;
  open?: (isOpen: boolean) => void;
  handleOpenNew?: () => void;
  handleOpenImport?: () => void;
  hasSelectorTime?: boolean;
  hasImport?: boolean;
  hasExport?: boolean;
  hasNew?: boolean;
}
export function CustomFooterStatusComponent() {
  return <Flex> </Flex>;
}

export function EditableGrid({
  hasCreateFilter = false,
  hasSelectorTime = false,
  hasNew = true,
  hasImport = true,
  hasExport = true,
  tabId,
  module,
  ...props
}: EditableGridProps) {
  const apiRef = useGridApiRef();
  const { isDisabled, formId, handleSaveCurrentTab } = React.useContext(FormContext);
  const { watch, setValue, reset } = useFormContext();
  const dispatch = useAppDispatch();

  const [isNew, setIsNew] = React.useState<boolean>(false);
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const {
    loading,
    columnSettings,
    onClickNew,
    onClickNewWithOutFormId,
    show,
    onConfirm,
    handleClose,
    saveRow,
  } = useEditableGrid({
    tabId,
    module,
    initialParams: {},
    parentId: formId,
    dataRows,
    setDataRows,
    setIsNew,
    handleSaveCurrentTab,
  });

  const [columns, setColumns] = React.useState(columnSettings);

  const processRowUpdate = React.useCallback(async (newRow: GridRowModel) => {
    return await saveRow(newRow);
  }, []);

  const onDragEnd = async (result: any) => {
    if (!result.targetIndex) {
      return;
    }
    const reordered: any[] = reorder(dataRows, result.oldIndex, result.targetIndex);
    const updateRows = reordered.map((row, index) => {
      return { ...row, posizione: index + 1 };
    });
    setDataRows(reordered);

    const posizioni = updateRows.map((row) => ({ id: row.id, posizione: row.posizione }));
    await dispatch(createPosition({ posizioni, moduleName: module }));
  };

  const ref = React.useRef<any>(null);

  React.useLayoutEffect(() => {
    const text = 'MUI X: Missing license key';

    const matches = [];

    for (const div of document.querySelectorAll('div')) {
      if (div.textContent === text) {
        ref.current = div;
      }
    }
  }, [document.querySelectorAll('div')]);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.style.display = 'none';
    }
  }, [ref.current]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGridPro
        apiRef={apiRef}
        rows={dataRows}
        columns={columns}
        loading={loading}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Footer: CustomFooterStatusComponent,
          Pagination: CustomFooterStatusComponent,
          NoRowsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          noRowsOverlay: { onClickNew, onClickNewWithOutFormId },
        }}
        rowReordering
        onRowOrderChange={(params: any) => onDragEnd(params)}
      />
      <ConfrimModal
        show={show}
        setShow={(show: boolean) => handleClose()}
        title={'Elimina riga'}
        onConfirm={onConfirm}
      >
        <Flex>Confermi di voler eliminare la riga selezionata?</Flex>
      </ConfrimModal>
    </div>
  );
}
export default React.memo(EditableGrid);
