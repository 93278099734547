import * as React from 'react';
import { getAction, SUBMIT, TabsId, useAppDispatch } from 'store';
import { MenuItem } from 'utils/types';
import { ModuleName } from 'utils/constants';
import { useGrid } from './hook';
import { Flex } from 'components';
import './style.css';
import { DataGrid, GridRowModel } from '@mui/x-data-grid';
import FormContext from 'components/Form/FormContext';
import { useFormContext } from 'react-hook-form';
import { useWindowSize } from 'hooks';
import { v4 as uuid } from 'uuid';
import { ConfrimModal } from 'components/ConfirmModal';

interface TableProps {
  tabId: TabsId;
  title: string;
  selectedId: number;
  menu?: MenuItem[];
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  hasCreateFilter?: boolean;
  module?: ModuleName;
  selectRow?: (id: number) => void;
  open?: (isOpen: boolean) => void;
  handleOpenNew?: () => void;
  handleOpenImport?: () => void;
  hasSelectorTime?: boolean;
  hasImport?: boolean;
  hasExport?: boolean;
  hasNew?: boolean;
  height?: string;
}

export function CustomFooterStatusComponent() {
  return <Flex> </Flex>;
}

export default function SimpleStickyTable(props: TableProps) {
  const { isDisabled, formId, handleSaveCurrentTab } = React.useContext(FormContext);
  const { watch, setValue, reset } = useFormContext();
  const dispatch = useAppDispatch();

  const [isNew, setIsNew] = React.useState<boolean>(false);
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const { loading, columnSettings, show, onConfirm, handleClose, saveRow, crudId } = useGrid({
    tabId: props.tabId,
    module: props.module as ModuleName,
    initialParams: {},
    parentId: formId,
    dataRows,
    setDataRows,
    setIsNew,
    handleSaveCurrentTab,
  });

  const window = useWindowSize();

  const [height, setHeight] = React.useState(0);
  const [paginationSize, setPaginationSize] = React.useState(0);

  const [columns, setColumns] = React.useState(columnSettings);

  const ref = React.useRef<HTMLDivElement>(null);

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel) => {
      console.log({ newRow });
      const currentRow = dataRows.find((row) => Number(row.id) === Number(newRow.id));
      if (currentRow && JSON.stringify(currentRow) !== JSON.stringify(newRow)) {
        saveRow(newRow);
      }
    },
    [formId, JSON.stringify(columnSettings)]
  );

  React.useLayoutEffect(() => {
    const offsetTop = ref?.current?.offsetTop;
    const heightW = window.height;
    console.log({ offsetTop, window });
    if (heightW) {
      const heightTable = heightW - 385 - 155;
      console.log({ heightTable });
      setHeight(heightTable);
      const pagSize = heightTable / 53;
      setPaginationSize(Math.ceil(pagSize) - 1);
    }
  }, [window?.height, ref?.current?.offsetTop]);

  return (
    <div style={{ height, width: '100%' }} ref={ref}>
      <DataGrid
        rows={dataRows ?? []}
        columns={columns}
        loading={loading}
        experimentalFeatures={{ newEditingApi: true }}
        getRowId={(row) => {
          const unique = uuid();
          processRowUpdate(row);
          return row ? (row.id as number) : unique;
        }}
        pageSize={dataRows?.length ?? 15}
        components={{
          Footer: CustomFooterStatusComponent,
          Pagination: CustomFooterStatusComponent,
        }}
        density="compact"
      />
      <ConfrimModal
        show={show}
        setShow={(show: boolean) => handleClose()}
        title={'Elimina riga'}
        onConfirm={onConfirm}
      >
        <Flex>Confermi di voler eliminare la riga con ID: {crudId}?</Flex>
      </ConfrimModal>
    </div>
  );
}
