import anagraficaSchema from 'components/Forms/Anagrafica/validation';
import filtroSchema from 'components/Forms/Filtro/valdiation';
import moduloChiaviSchema from 'components/Forms/Modulo/chiaviValidation';
import moduloSchema from 'components/Forms/Modulo/valdiation';
import schedaSchema from 'components/Forms/Scheda/schedaSchema';
import {
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_ID,
  MODULO_RIGHE_ID,
  REFERENTE_ID,
  SCHEDA_ID,
  TabsId,
} from 'store';

export const MapValidationSchemas: Record<TabsId | string, any> = {
  [ANAGRAFICA_ID]: anagraficaSchema,
  [FILTRO_ID]: filtroSchema,
  [MODULO_ID]: moduloSchema,
  [MODULO_RIGHE_ID]: moduloChiaviSchema,
  [SCHEDA_ID]: schedaSchema,
  [REFERENTE_ID]: null,
};

export default MapValidationSchemas;
