import { queryString } from './index';
import axios from 'axios';
import checkTokenValidity from './checkTokenValidity';
import { BASE } from '../constants';
import { MultipartUrl, ParamsUrl } from '../constants/types';

const instance = axios.create({
  baseURL: BASE,
  headers: { 'content-type': 'application/json' },
});

instance.interceptors.request.use(
  async (config) => {
    const token = await checkTokenValidity();
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['X-PAGHE-APP'] = '0';
      return config;
    }
    delete instance.defaults.headers.common['Authorization'];
    return config;
  },
  (error) => {
    Promise.reject(error); //TODO Handle Promise return
  }
);

export const axiosGet = async <AxiosResponse>({
  url,
  params,
}: ParamsUrl): Promise<AxiosResponse> => {
  const query = queryString(params);
  try {
    return instance.get(`${url}${query || ''}`);
  } catch (error: any) {
    return error;
  }
};

export const axiosPost = async <AxiosResponse>({
  url,
  params,
}: ParamsUrl): Promise<AxiosResponse> => {
  try {
    return instance.post(url, params);
  } catch (error: any) {
    return error;
  }
};

export const axiosDelete = async <AxiosResponse>({
  url,
  params,
}: ParamsUrl): Promise<AxiosResponse> => {
  try {
    return instance.delete(url, params);
  } catch (error: any) {
    return error;
  }
};

export const axiosMultipart = async <AxiosResponse>({
  url,
  formData,
}: MultipartUrl): Promise<AxiosResponse> => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return instance.post(url, formData, config);
  } catch (error: any) {
    return error;
  }
};

export default class apiClient {
  static get = axiosGet;
  static post = axiosPost;
  static delete = axiosDelete;
  static multipart = axiosMultipart;
}
