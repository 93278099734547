import { FormInput, SelectForm } from 'components';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const DatiGenerali = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Dati generali</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <FormInput
              type="text"
              name="ragione_sociale"
              label={'Ragione Sociale'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <FormInput
              type="text"
              name="referente"
              label={'Legale rappresentante'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          {/* <Col md={4}>
            <FormInput
              type="text"
              name="piva"
              label="P.IVA"
              placeholder="01234567890"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="text"
              name="codice_fiscale"
              label="Cod. fiscale"
              placeholder="GHBMNF67R09D462N"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col> */}
          <Col md={6}>
            <FormInput
              type="text"
              name="piva"
              label="P.IVA"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <FormInput
              type="text"
              name="codice_fiscale"
              label="Cod. fiscale"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={4}>
            <FormInput
              type="text"
              name="categoria"
              label={'Categoria'}
              placeholder="Artigiano"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <SelectForm
              label={'Gruppo Anagrafico'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
              name="gruppo_anagrafico"
              elements={[
                { id: 'CLIENTE', descrizione: 'Cliente' },
                { id: 'FORNITORE', descrizione: 'Fornitore' },
                { id: 'DIPENDENTE', descrizione: 'Dipendente' },
                { id: 'GENERICO', descrizione: 'Generico' },
              ]}
            />
          </Col>
          <Col md={4}>
            <SelectForm
              label={'Tipologia'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
              name="tipologia"
              elements={[
                { id: 'FPR', descrizione: 'Azienda/Ditta individuale' },
                { id: 'FPF', descrizione: 'Persona fisica' },
                { id: 'FPA', descrizione: 'Pubblica amministrazione' },
                { id: 'ALT', descrizione: 'Altro' },
              ]}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="text"
              name="codice"
              label={'Cod. Fattura24'}
              placeholder="123456"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default DatiGenerali;
