import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, URL_ITEM, URL_LOCK, URL_REMOVE } from 'store/utils';
import { LockParams } from '../types';

export const unlock = createAsyncThunk(
  'lock/unlock',
  async (parameters: LockParams, thunkAPI) => {
    const { module, module_id } = parameters;
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_LOCK}${URL_REMOVE}/${module}${URL_ITEM}/${module_id}`,
        params: {},
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
