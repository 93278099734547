import { RootState } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModuloDeleteParams } from '../types';
import { apiClient, handleError, URL_MODULO, URL_DELETE_EMPTY_ROWS, URL_V1 } from '../../../index';
import { AxiosResponse } from 'axios';
import { setModuloSelectedIds } from 'store/moduli';

export const deleteEmptyRowModulo = createAsyncThunk(
  'modulo/delete',
  async (params: ModuloDeleteParams, thunkAPI) => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.delete<AxiosResponse>({
        url: `${URL_MODULO}${URL_DELETE_EMPTY_ROWS}/${params.id}`,
      });
      if (response.status === 200) {
        if (state?.moduli?.selectedIds.includes(params.id)) {
          thunkAPI.dispatch(setModuloSelectedIds(params.id));
        }
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
