import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, removeTokenCookies } from '../../utils';

import { URL_LOGOUT, URL_USER } from '../../utils/constants';

export const getLogout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    const response = await apiClient.get<AxiosResponse>({
      url: `${URL_USER}${URL_LOGOUT}`,
      params: {},
    });
    if (response.status === 200) {
      removeTokenCookies();
      return response.data;
    }
    removeTokenCookies();
    return handleError(response.status, thunkAPI, response.data?.status);
  } catch (error: any) {
    return handleError(error?.response?.status, thunkAPI);
  }
});
