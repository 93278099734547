import { Box, FormInput, SimpleTable } from 'components';
import FileUploader from 'components/FileUploader';
import Loader from 'components/Loader';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  getImportazione,
  getImportazioneAdded,
  getImportazioneHeader,
  getImportazioneLoader,
  getImportazioneNotImported,
  getImportazioneRows,
  getImportazioneUpdated,
  importFile,
  resetImportazione,
} from 'store/importazioni/importazione';
import { confirmImport } from 'store/importazioni/importazione/actions/confirm';
import { ModuleName } from 'utils/constants';
import { FETCHLIST, getAction, TabsId, useAppDispatch } from '../../../store';

interface ImportazioneFormProps {
  tabId: TabsId;
  module: ModuleName;
  accept: string;
}

export const ImportazioneForm = ({ tabId, module, accept }: ImportazioneFormProps) => {
  const dispatch = useAppDispatch();
  const [showTable, setShowTable] = useState<boolean>(false);
  const [imported, setImported] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const header = useSelector(getImportazioneHeader);
  const rows = useSelector(getImportazioneRows);
  const added = useSelector(getImportazioneAdded);
  const updated = useSelector(getImportazioneUpdated);
  const notImported = useSelector(getImportazioneNotImported);

  const isLoading = useSelector(getImportazioneLoader);
  const importazione = useSelector(getImportazione);

  const onFileUpload = (ev: ChangeEvent<HTMLInputElement>) => {
    const fileList = ev?.target?.files;
    if (fileList) {
      const file = fileList[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = dispatch(importFile({ module, formData }));
    }
  };

  useEffect(() => {
    setShowTable(header.length > 0 && rows.length > 0);
    rows.length > 0 ? setData(rows) : setData([]);
  }, [header, rows]);

  const resetImport = () => {
    dispatch(resetImportazione());
    setImported(false);
    setShowTable(false);
  };

  const setNotImportedData = () => {
    setData(notImported);
    setShowTable(true);
  };

  const setAddedData = () => {
    setData(added);
    setShowTable(true);
  };

  const setUpdated = () => {
    setData(updated);
    setShowTable(true);
  };

  const confirmImportazione = async () => {
    await dispatch(confirmImport({ module, id: importazione?.id ?? 0 }));
    setShowTable(false);
    setImported(true);
    const fetch = getAction(tabId, FETCHLIST);
    fetch && dispatch(fetch());
  };

  return (
    <Box height={'100%'}>
      <Row>
        <Col>
          <ol>
            <li>Seleziona un file da importare con estensione {accept}</li>
            <li>Controlla i dati visualizzati in tabella</li>
            <li>Se corretti procedi con l'importazione</li>
            <li>Altrimenti correggi i dati ed effettua nuovamente il precaricamento</li>
          </ol>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormInput
            type="file"
            name="file"
            label="Seleziona file da importare"
            placeholder="01234567890"
            minHeight="6.25rem"
            accept={accept}
            onChange={onFileUpload}
          />
        </Col>
        <Col md={8} style={{ marginTop: '1.75rem' }}>
          {imported ? (
            <div style={{ paddingBottom: '2rem' }}>
              <Button
                variant="soft-danger"
                onClick={setNotImportedData}
                style={{
                  backgroundColor: 'gray-300',
                  margin: '0.2rem',
                }}
              >
                Non importati ({notImported.length ?? 0})
              </Button>
              <Button
                variant="soft-success"
                onClick={setAddedData}
                style={{
                  backgroundColor: 'primary',
                  margin: '0.2rem',
                }}
              >
                Nuovi ({added.length ?? 0})
              </Button>
              <Button
                variant="soft-info"
                onClick={setUpdated}
                style={{
                  backgroundColor: 'primary',
                  margin: '0.2rem',
                }}
              >
                Aggiornati ({updated.length ?? 0})
              </Button>
            </div>
          ) : (
            rows.length > 0 && (
              <div style={{ paddingBottom: '2rem' }}>
                <Button
                  variant="soft-secondary"
                  onClick={resetImport}
                  style={{
                    backgroundColor: 'gray-300',
                    margin: '0.2rem',
                  }}
                >
                  Annulla importazione
                </Button>
                <Button
                  variant="soft-primary"
                  onClick={confirmImportazione}
                  style={{
                    backgroundColor: 'primary',
                    margin: '0.2rem',
                  }}
                >
                  Importa
                </Button>
              </div>
            )
          )}
        </Col>
      </Row>
      {isLoading && <Loader />}

      <Row style={{ overflowY: 'auto', height: '100%' }}>
        {showTable && <SimpleTable header={header} rows={data} />}
      </Row>
    </Box>
  );
};

export default ImportazioneForm;
