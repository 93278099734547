import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown, ButtonGroup, Card, Button } from 'react-bootstrap';
import Icon from '@ailibs/feather-react-ts';
import { Dialog, Form, Grid, ImportazioneForm } from '../../components';
import {
  FILTRO_ID,
  dispatchAction,
  FETCH,
  Anagrafica,
  getSelectorElement,
  getSelectorElementById,
  getSelectorListData,
  RESET,
  useAppDispatch,
  getAction,
} from '../../store';
import { Link } from 'react-router-dom';
import Tasks from 'components/Tasks';
import { ANAGRAFICA, FILTRO } from 'utils/constants';
import { resetImportazione } from 'store/importazioni/importazione';
import { useDisclosure } from 'hooks';

// dummy data

const Filtri = () => {
  const dispatch = useAppDispatch();

  const [dateRange, setDateRange] = useState<any>([
    new Date(),
    new Date().setDate(new Date().getDate() + 7),
  ]);
  const [startDate, endDate] = dateRange;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };

  const reset = () => {
    const actionReset = getAction(FILTRO_ID, RESET);
    actionReset && dispatch(actionReset);
    setSelectedId(0);
  };

  const showDialogImport = (show: boolean) => {
    setShowImport(show);
    !show && dispatch(resetImportazione());
  };

  const handleOpenImport = () => {
    setShowImport(true);
  };

  const handleOpenNew = () => {
    reset();
    setIsOpen(true);
  };

  const onClose = () => {
    reset();
    setIsOpen(false);
  };

  /*
   * handle date change
   */
  const onDateChange = (date: Date) => {
    if (date) {
      setDateRange(date);
    }
  };

  useEffect(() => {
    if (selectedId) {
      const actionFetch = getAction(FILTRO_ID, FETCH);
      actionFetch && dispatch(actionFetch({ id: selectedId }));
    }
  }, [selectedId]);

  return (
    <Row>
      <Col style={{ paddingTop: '1rem' }}>
        <Grid
          tabId={FILTRO_ID}
          module={FILTRO}
          pagination
          title="Elenco Filtri"
          selectedId={selectedId}
          selectRow={setSelectedId}
          open={setIsOpen}
          handleOpenNew={handleOpenNew}
          handleOpenImport={handleOpenImport}
          hasNew={false}
          hasExport={false}
          hasImport={false}
          isExpandable
        />
      </Col>
      <Form
        id={selectedId}
        isOpen={isOpen}
        tabId={FILTRO_ID}
        module={FILTRO}
        handleClose={onClose}
        hasSave={false}
      />
    </Row>
  );
};

export default Filtri;
