import * as React from 'react';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Flex from './Flex';

interface AdvancedPopoverInterface {
  anchorElement: React.ReactElement;
  children: React.ReactElement;
  anchorOriginVertical?: PopoverOrigin['vertical'];
  anchorOriginHorizontal?: PopoverOrigin['horizontal'];
  transformOriginVertical?: PopoverOrigin['vertical'];
  transformOriginHorizontal?: PopoverOrigin['horizontal'];
}

export const AdvancedPopover = ({
  anchorElement,
  children,
  anchorOriginVertical = 'center',
  anchorOriginHorizontal = 'right',
  transformOriginVertical = 'center',
  transformOriginHorizontal = 'left',
}: AdvancedPopoverInterface) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const extendedAnchorElement = React.cloneElement(anchorElement, { onClick });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Flex>
      {extendedAnchorElement}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
        onClose={handleClose}
      >
        {children}
      </Popover>
    </Flex>
  );
};

export default AdvancedPopover;
