import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { LoginRequest } from '../types';
import { addTokenCookies, apiClient, handleError } from '../../utils';

import { URL_LOGIN, URL_USER, OK } from '../../utils/constants';

export const postSignIn = createAsyncThunk(
  'auth/signIn',
  async ({ username, password }: LoginRequest, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_USER}${URL_LOGIN}`,
        params: { username, password },
      });
      if (response.status === 200) {
        addTokenCookies({
          token: response.data.data.token,
          refreshToken: response.data.data.refresh_token,
        });
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
