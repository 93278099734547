import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUser, LockStatus } from 'store';

interface FormButtonsProps {
  id: number | null;
  handleClose: (cancel: boolean) => void;
  handleSave: () => void;
  isDisabled: boolean;
  handleSaveAndClose?: () => void;
  handleLockStatus?: () => Promise<LockStatus>;
  handleUnlock?: () => void;
  isLoading?: boolean;
  hasLockState?: boolean;
  isDirty?: boolean;
  hasSave?: boolean;
  revertAndCloseText?: string;
  closeText?: string;
  saveText?: string;
  saveAndCloseTest?: string;
}

const FormButtons = ({
  handleLockStatus,
  handleUnlock,
  handleClose,
  handleSave,
  handleSaveAndClose,
  id,
  isDisabled = false,
  hasLockState = false,
  isLoading = true,
  isDirty = true,
  hasSave = true,
  revertAndCloseText = 'Annulla e Chiudi',
  closeText = 'Chiudi',
  saveText = 'Salva',
  saveAndCloseTest = 'Salva e Chiudi',
}: FormButtonsProps): React.ReactElement => {
  const [isEditable, setIsEditable] = React.useState(false);
  const currentUser = useSelector(getUser);

  const handleEdit = async () => {
    if (handleLockStatus) {
      console.log({ currentUser });
      const lock = await handleLockStatus();
      setIsEditable(lock?.email === currentUser.email);
    }
  };

  const save = (close: boolean) => {
    close ? handleSaveAndClose && handleSaveAndClose() : handleSave();
    if (hasLockState && isEditable && handleUnlock) {
      handleUnlock();
      setIsEditable(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '6.2rem',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        alignItems: 'center',
        borderTop: '1px solid',
        bottom: '0',
      }}
    >
      <div>
        <Button
          variant="soft-secondary"
          onClick={() => handleClose(true)}
          style={{
            backgroundColor: 'gray-300',
            margin: '0.2rem',
          }}
        >
          {!isDisabled ? revertAndCloseText : closeText}
        </Button>
        {hasLockState && isDisabled && id && (
          <>
            <Button
              variant="primary"
              onClick={handleEdit}
              style={{
                backgroundColor: 'primary',
                margin: '0.2rem',
              }}
            >
              Modifica
            </Button>
          </>
        )}
        {hasSave && (!hasLockState || !isDisabled) && (
          <>
            <Button
              variant="soft-primary"
              onClick={() => save(false)}
              style={{
                backgroundColor: 'primary',
                margin: '0.2rem',
              }}
            >
              {saveText}
            </Button>
            <Button
              variant="primary"
              onClick={() => save(true)}
              style={{
                backgroundColor: 'primary',
                margin: '0.2rem',
              }}
            >
              {saveAndCloseTest}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(FormButtons);
