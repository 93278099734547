import Icon from '@ailibs/feather-react-ts';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BoxIcon,
  Form,
  FormInput,
  Grid,
  SelectForm,
  SimpleStickyTable,
  Text,
  Flex,
} from 'components';
import { AlertMessage } from 'components/AlertMessage';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  createReferente,
  FETCH,
  fetchReferenti,
  fetchReferentiOptions,
  getAction,
  ReferenteClass,
  REFERENTE_ID,
  useAppDispatch,
} from 'store';
import { REFERENTE } from 'utils';
import schema from './validationReferente';
export const DatiReferenti = () => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const methods = useForm<any>({
    mode: 'onBlur',
    defaultValues: new ReferenteClass(),
    resolver: yupResolver(schema),
  });

  //TODO settare il parentId nei params di referente
  const id = watch('id');

  const dispatch = useAppDispatch();

  const [selectedId, setSelectedId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const reset = () => {
    // const actionReset = getAction(ANAGRAFICA_ID, RESET);
    // actionReset && dispatch(actionReset);
    setSelectedId(0);
  };

  const onClose = () => {
    reset();
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId) {
      const actionFetch = getAction(REFERENTE_ID, FETCH);
      actionFetch && dispatch(actionFetch({ id: selectedId }));
    }
  }, [selectedId]);

  const {
    register,
    trigger: triggerReferente,
    getValues: referenteGetValues,
    reset: resetReferente,
    formState: { errors: referenteErrors },
  } = methods;

  const handleClick = async () => {
    const item = referenteGetValues();
    item.anagrafica_id = id;
    const noErrors = await triggerReferente();
    if (noErrors) {
      const response = await dispatch(createReferente(item));
      if (!!response && !response.error) {
        const elementResponse = response?.payload['data'];
        // @ts-ignore
        resetReferente(new ReferenteClass());

        dispatch(fetchReferenti());
        AlertMessage({
          title: 'Perfetto',
          text: 'Record salvato con successo',
          icon: 'success',
          allowOutsideClick: false,
        });
        return elementResponse;
      }
    }
  };

  return (
    <React.Fragment>
      <Card className="mb-3">
        <Card.Header>
          <span>Referente</span>
        </Card.Header>
        <Card.Body>
          <FormProvider {...methods}>
            <Row>
              <Col md={2}>
                <FormInput
                  type="text"
                  name="titolo"
                  label="titolo"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                />
              </Col>
              <Col md={2}>
                <FormInput
                  type="text"
                  name="ruolo"
                  label="Ruolo"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                />
              </Col>
              <Col md={4}>
                <FormInput
                  type="text"
                  name="nome"
                  label="Nome"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                />
              </Col>
              <Col md={4}>
                <FormInput
                  type="text"
                  name="cognome"
                  label="Cognome"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormInput
                  type="text"
                  name="telefono"
                  label="Telefono fisso"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                  startIcon={<Icon name="phone" />}
                />
              </Col>
              <Col md={4}>
                <FormInput
                  type="text"
                  name="cellulare"
                  label="Cellulare"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                  startIcon={<Icon name="smartphone" />}
                />
              </Col>
              <Col md={4}>
                <FormInput
                  type="text"
                  name="email"
                  label="E-mail"
                  minHeight="6.25rem"
                  register={register}
                  errors={referenteErrors}
                  startIcon={<Icon name="send" />}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormInput
                  type="checkbox"
                  name="presente_whatsapp"
                  label="Presente su gruppo WhatsApp"
                  register={register}
                  errors={referenteErrors}
                />
              </Col>
              <Col md={4}></Col>
              <Col md={4}>
                <Flex onClick={handleClick} cursor={'pointer'}>
                  <BoxIcon iconName="plus-circle" />
                  <Text
                    text="Aggiungi referente"
                    paddingLeft="1rem"
                    cursor={'pointer'}
                    fontSize="18px"
                    fontWeight={'bold'}
                  />
                </Flex>
              </Col>
            </Row>
          </FormProvider>
        </Card.Body>
      </Card>
      <SimpleStickyTable
        tabId={REFERENTE_ID}
        module={REFERENTE}
        pagination={false}
        title="Elenco Referenti"
        selectedId={selectedId}
        selectRow={setSelectedId}
        open={setIsOpen}
        hasImport={false}
        hasExport={false}
        hasNew={false}
        height="42vh"
      />
    </React.Fragment>
  );
};

export default DatiReferenti;
