import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getSchedaState = (state: RootState) => state.scheda;

export const getSchedaLoader = createSelector(
  getSchedaState,
  (schedaState) => schedaState.loading === PENDING
);

export const getSchedaFailed = createSelector(
  getSchedaState,
  (schedaState) => schedaState.loading === FAILED
);

export const getSchedaSuccess = createSelector(
  getSchedaState,
  (schedaState) => schedaState.loading === SUCCEEDED
);

export const getSchedaError = createSelector(getSchedaState, (schedaState) => schedaState?.error);

export const getScheda = createSelector(getSchedaState, (schedaState) => schedaState?.data);
export const getSchedaList = createSelector(
  getSchedaState,
  (schedaState) => schedaState?.list ?? []
);
export const getSchedaConfig = createSelector(getSchedaState, (schedaState) => schedaState?.config);
