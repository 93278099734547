import {
  TabsId,
  ANAGRAFICA_ID,
  LOGIN_ID,
  CONFIRM_ID,
  REGISTER_ID,
  FILTRO_ID,
  MODULO_ID,
  DRIVE_TEST_ID,
  SCHEDA_ID,
  REFERENTE_ID,
} from 'store';

import React, { lazy } from 'react';

const AnagraficaForm = lazy(() => import('../Forms/Anagrafica'));
const FiltroEdit = lazy(() => import('../Forms/FiltroEditForm'));
const SchedaForm = lazy(() => import('../Forms/Scheda'));
const ModuloEdit = lazy(() => import('../Forms/Modulo'));

export const Forms: Record<TabsId, React.ReactElement> = {
  [LOGIN_ID]: <div />,
  [CONFIRM_ID]: <div />,
  [REGISTER_ID]: <div />,
  [ANAGRAFICA_ID]: <AnagraficaForm id={0} element={undefined} />,
  [FILTRO_ID]: <FiltroEdit id={0} element={undefined} />,
  [MODULO_ID]: <ModuloEdit id={0} element={undefined} />,
  [SCHEDA_ID]: <SchedaForm id={0} element={undefined} />,
  [REFERENTE_ID]: <div />,
  [DRIVE_TEST_ID]: <div />,
};

export default Forms;
