import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState, URL_SELECT_ALL } from '../../..';
import { URL_FILTRO, URL_LIST } from '../../..';

export const selectFiltroAll = createAsyncThunk(
  'filtri/selectAll',
  async (_, thunkAPI): Promise<any> => {
    const state = thunkAPI?.getState() as RootState;
    const params = state?.filtri?.params ?? {};
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_FILTRO}${URL_SELECT_ALL}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
