import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setModuloChiaviParams } from '.';
import { apiClient, handleError, RootState, URL_MODULO_RIGHE } from '../../..';
import { URL_MODULO, URL_LIST } from '../../..';

export const fetchModuloChiavi = createAsyncThunk(
  'moduloChiavi/fetch',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = state?.moduloChiavi?.params ?? {};
      const { parentId, ...rest } = params;
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_MODULO}${URL_MODULO_RIGHE}${URL_LIST}/${parentId}`,
        params: rest,
      });
      if (response.status === 200) {
        const info = response.data?.data?.info;
        if (info) {
          const currentPage = info.currentPage;
          const paramsPage = params.skip / params.limit + 1;
          currentPage < paramsPage &&
            thunkAPI.dispatch(setModuloChiaviParams({ ...params, skip: 0 }));
        }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
