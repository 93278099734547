import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// layout constants
import { LayoutTypes, LayoutWidth, SideBarTypes, SideBarTheme } from '../constants/layout';

// strore
import { checkTokenValidity, RootState } from '../store';

// All layouts containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import TwoColumnLayout from '../layouts/TwoColumn/';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import { ANAGRAFICHE, FILTRI, HOME_START, LOGIN, MODULI, SCHEDE, START } from 'utils';
import Loader from 'components/Loader';
import useAuth from 'hooks/useAuth';
import Home from 'pages/home';
import Anagrafiche from 'pages/anagrafiche';
import Moduli from 'pages/moduli';
import Filtri from 'pages/filtri';
import Schede from 'pages/schede';
import Login from 'pages/auth/Login';
import useInit from 'hooks/store/init';

interface RoutesProps {}

function RequireAuth() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  // const { loader } = useInit();

  useEffect(() => {
    checkTokenValidity();
  }, [location]);

  if (!isAuthenticated) {
    return <Navigate to={LOGIN} state={{ from: location }} replace />;
  }

  return <Outlet />;
}

const AppRoutes = (props: RoutesProps) => {
  // const Login = React.lazy(() => import('../pages/auth/Login'));
  // const Home = React.lazy(() => import('../pages/home'));
  // const Anagrafiche = React.lazy(() => import('../pages/anagrafiche'));
  // const Filtri = React.lazy(() => import('../pages/filtri'));
  // const Moduli = React.lazy(() => import('../pages/moduli'));
  // const Schede = React.lazy(() => import('../pages/schede'));

  const { layout } = useSelector((state: RootState) => ({
    layout: {
      layoutType: LayoutTypes.LAYOUT_HORIZONTAL,
      layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
      leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
      leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
      showRightSidebar: false,
    }, //state.Layout,
  }));

  // const getLayout = () => {
  //   let layoutCls = HorizontalLayout;

  //   switch (layout.layoutType) {
  //     case LayoutTypes.LAYOUT_HORIZONTAL:
  //       layoutCls = HorizontalLayout;
  //       break;
  //     case LayoutTypes.LAYOUT_DETACHED:
  //       layoutCls = DetachedLayout;
  //       break;
  //     case LayoutTypes.LAYOUT_VERTICAL:
  //       layoutCls = VerticalLayout;
  //       break;
  //     default:
  //       layoutCls = HorizontalLayout;
  //       break;
  //   }
  //   return layoutCls;
  // };

  // const Layout = getLayout();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={LOGIN}
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route element={<RequireAuth />}>
          <Route element={<HorizontalLayout />}>
            <Route
              path={START}
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path={HOME_START}
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path={ANAGRAFICHE}
              element={
                <Suspense fallback={<Loader />}>
                  <Anagrafiche />
                </Suspense>
              }
            />
            <Route
              path={MODULI}
              element={
                <Suspense fallback={<Loader />}>
                  <Moduli />
                </Suspense>
              }
            />
            <Route
              path={FILTRI}
              element={
                <Suspense fallback={<Loader />}>
                  <Filtri />
                </Suspense>
              }
            />
            <Route
              path={SCHEDE}
              element={
                <Suspense fallback={<Loader />}>
                  <Schede />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
