import { MODULO } from 'utils/constants';
import { useDispatch } from 'react-redux';
import {
  ActionType,
  ANAGRAFICA_ID,
  confirmUser,
  CONFIRM_ID,
  getActionType,
  LOGIN_ID,
  postSignIn,
  REFERENTE_ID,
  registerUser,
  REGISTER_ID,
  TabsId,
  useAppDispatch,
} from 'store';
import {
  AnagraficaMapAction,
  AnagraficheMapAction,
  ReferenteMapAction,
  ReferentiMapAction,
} from '../anagrafiche';
import { LoginActions } from '../auth/types/mapAction';
import { FiltroMapAction, FiltriMapAction } from 'store/filtri';
import { SchedaMapAction, SchedeMapAction } from '../schede';
import {
  ModuliMapAction,
  ModuloMapAction,
  ModuloChiaveMapAction,
  ModuloChiaviMapAction,
} from 'store/moduli';

export const mapAction: Record<TabsId, Record<ActionType | string, any>> = {
  LOGIN: { ...LoginActions },
  // [REGISTER_ID]: {
  //   submit: registerUser,
  // },
  // [CONFIRM_ID]: {
  //   submit: confirmUser,
  // },
  ANAGRAFICA: { ...AnagraficheMapAction, ...AnagraficaMapAction },
  FILTRO: { ...FiltroMapAction, ...FiltriMapAction },
  MODULO: { ...ModuloMapAction, ...ModuliMapAction },
  MODULO_RIGHE: { ...ModuloChiaveMapAction, ...ModuloChiaviMapAction },
  SCHEDA: { ...SchedaMapAction, ...SchedeMapAction },
  REFERENTE: { ...ReferenteMapAction, ...ReferentiMapAction },
};

export const getAction = (tabId: TabsId, actionType: ActionType, options = false) => {
  const typeAction = getActionType(actionType, options);
  const action = mapAction[tabId][typeAction];
  return action;
};

export function dispatchAction(
  tabId: TabsId,
  actionType: ActionType,
  params: { [key: string]: any } | Record<string, any> | undefined = undefined,
  options: boolean = false
) {
  const dispatch = useDispatch();
  const action = getAction(tabId, actionType, options);
  return action && dispatch(action(params));
}
