import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  DRIVE_TEST_ID,
  getAction,
  getSelectorListDataInfo,
  getSelectorSelectedIds,
  mapAction,
  TabsId,
  useAppDispatch,
} from '../store';
import { Button } from 'react-bootstrap';
import Flex from './Flex';
import { Divider, IconButton, InputBase, Pagination, Paper } from '@mui/material';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { Box } from 'components';

interface TablePaginationProps {
  tabId: TabsId;
  params: Record<string, any>;
  updateParams: ({ skip, limit, ...rest }: any) => void;
  initialLimit?: number;
}

const TablePagination = ({
  tabId,
  params,
  updateParams,
  initialLimit = 10,
}: TablePaginationProps) => {
  /**
   * pagination count , index
   */
  if (tabId === DRIVE_TEST_ID) return <></>;

  const dispatch = useAppDispatch();

  const { pages, total, currentPage } = getSelectorListDataInfo(tabId);
  const selectedIds: any[] = getSelectorSelectedIds(tabId);
  const [pageCount, setPageCount] = useState<number>(pages ?? 1);
  const [pageIndex, setPageIndex] = useState<number>(currentPage ?? 1);
  const [manualPage, setManualChange] = useState<number>(currentPage ?? 1);

  useEffect(() => {
    setPageCount(pages);
    setPageIndex(currentPage);
  }, [pages, currentPage]);

  const onChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    console.log({ newPage, pageIndex });
    setPageIndex(newPage);
    const newSkip = (newPage - 1) * params.limit;
    console.log('onChange newskip', { newSkip });
    updateParams({ skip: newSkip });
  };

  const changePage = (page: number) => {
    if (page === currentPage) {
      return;
    }
    setPageIndex(page);
    const startPage = page - 1;
    const skip = params.limit ? Math.ceil(startPage * params.limit) : 0;
    updateParams({ ...params, skip });
    //tableProps.gotoPage(page - 1);
  };

  const handleClick = () => {
    if (manualPage < 1 || manualPage > pages) return;
    changePage(manualPage);
  };

  const activePage: number = pageIndex;

  return (
    <Flex justifyContent={'space-between'} width="100%">
      <Flex>
        <Flex alignItems={'center'}>
          <label className="me-1">Visualizza :</label>
          <select
            style={{ padding: '0.25rem 1.25rem .25rem 0.25rem' }}
            value={initialLimit}
            onChange={(e: any) => {
              updateParams({ ...params, limit: Number(e.target.value) });
            }}
            className="form-select d-inline-block w-auto"
          >
            <option key={total} value={total}>
              ** Tutti gli elementi **
            </option>
            <option key={initialLimit} value={initialLimit}>
              {initialLimit}
            </option>
            <option key={20} value="20">
              20
            </option>
            <option key={50} value="50">
              50
            </option>
            <option key={100} value="100">
              100
            </option>
            <option key={500} value="500">
              500
            </option>
          </select>
          <label className="me-1" style={{ paddingLeft: '1rem' }}>
            di {total}
          </label>
        </Flex>
        <Flex paddingLeft="1rem">
          <Paper
            component="form"
            sx={{ p: '0', display: 'flex', alignItems: 'center', width: 200, height: 34 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              type="number"
              onChange={(e: any) => {
                const page = e.target.value ? Number(e.target.value) : 1;
                setManualChange(page > pages ? pages : page);
              }}
              placeholder="Vai alla pagina"
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={handleClick}
            >
              <ArrowRightOutlinedIcon />
            </IconButton>
          </Paper>
        </Flex>
      </Flex>
      <Flex justifyContent={'end'} alignItems="center">
        <Pagination
          count={pages}
          defaultPage={1}
          page={pageIndex ?? 1}
          boundaryCount={0}
          siblingCount={0}
          variant="outlined"
          color="primary"
          showFirstButton
          showLastButton
          onChange={onChange}
        />
      </Flex>
    </Flex>
  );
};

export default TablePagination;
