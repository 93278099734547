import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { CreatePositionParams } from '../types';
import { apiClient, handleError, URL_CREATE_POSITION, URL_SETTING } from 'store/utils';

export const createPosition = createAsyncThunk(
  'setting/create_position',
  async (parameters: CreatePositionParams, thunkAPI) => {
    const { moduleName, posizioni } = parameters;
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_SETTING}/${moduleName}${URL_CREATE_POSITION}`,
        params: [...posizioni],
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
