import { createAsyncThunk } from '@reduxjs/toolkit';
import { FiltroCreateParams } from '../types';
import { apiClient, handleError, URL_FILTRO, URL_CREATE, fetchFiltriOptions } from '../../../index';
import { AxiosResponse } from 'axios';

export const createFiltro = createAsyncThunk(
  'filtro/create',
  async (params: FiltroCreateParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_FILTRO}${URL_CREATE}`,
        params,
      });
      if (response.status === 200) {
        thunkAPI.dispatch(fetchFiltriOptions());
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
