import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState } from '../../..';
import { URL_SCHEDA, URL_LIST } from '../../..';

export const fetchSchedeOptions = createAsyncThunk(
  'schede/fetchOPtions',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = state?.anagrafiche?.paramsOption ?? {};
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_SCHEDA}${URL_LIST}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
