import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState, URL_SELECT_ALL } from '../../../..';
import { URL_REFERENTE, URL_LIST } from '../../../..';

export const selectReferenteAll = createAsyncThunk(
  'referenti/selectAll',
  async (_, thunkAPI): Promise<any> => {
    const state = thunkAPI?.getState() as RootState;
    const params = state?.referenti?.params ?? {};
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_REFERENTE}${URL_SELECT_ALL}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
