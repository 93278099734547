import { FormInput, Text } from 'components';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const DatiFatturazione = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Dati Fatturazione</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={3}>
            <FormInput
              type="text"
              name="codice_destinatario"
              label="Codice Destinatario"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="number"
              name="canone_fisso"
              label="Canone fisso"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              startIcon={<Text text="€" marginBottom={'0'} />}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="number"
              name="quota_cedolino"
              label="Quota per cedolino"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              startIcon={<Text text="€" marginBottom={'0'} />}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DatiFatturazione;
