import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { URL_IMPORTAZIONE, URL_FILE, apiClient, handleError } from 'store/utils';
import { ImportazioneParams } from '../types/params';

export const importFile = createAsyncThunk(
  'importazione/upload',
  async ({ module, formData }: ImportazioneParams, thunkAPI) => {
    try {
      const response = await apiClient.multipart<AxiosResponse>({
        url: `${URL_IMPORTAZIONE}/${module}${URL_FILE}`,
        formData,
      });

      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI);
    }
  }
);
