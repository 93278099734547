import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface CellProps {
  handleChange: (newValue: any) => void;
  id: any;
  field: any;
  value: any;
}

export const DateCellEditableGrid = ({ handleChange, id, field, value }: CellProps) => {
  return (
    <DatePicker
      value={value}
      renderInput={(params: any) => <TextField {...params} />}
      onChange={handleChange}
    />
  );
};

export default DateCellEditableGrid;
