import FormButtons from 'components/FormButtons';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { TabsId } from 'store';
import Forms from './children';
import FormContext from './FormContext';
import { ModuleName } from 'utils/constants';
import { useCrudForm } from 'hooks';
import { Dialog } from 'components';

interface FormProps {
  title?: string;
  isOpen: boolean;
  id: number | null;
  tabId: TabsId;
  handleClose: () => void;
  module: ModuleName;
  isModal?: boolean;
  hasSave?: boolean;
  deleteEmptyChild?: boolean;
  hasLockState?: boolean;
  minHeight?: string;
}

const Form = ({
  title = '',
  isOpen,
  id,
  tabId,
  handleClose,
  module,
  hasSave = true,
  isModal = false,
  deleteEmptyChild = false,
  hasLockState = false,
  minHeight = '20rem',
  ...rest
}: FormProps & any) => {
  const component = Forms[tabId];

  const {
    methods,
    closeAndResetForm,
    handleSave,
    handleSaveAndClose,
    isLoading,
    element,
    handleSaveCurrentTab,
    hanldeLockStatus,
    handleUnlock,
    isDisabled,
  } = useCrudForm({
    id,
    tabId,
    handleClose,
    deleteEmptyChild,
    module,
  });
  const formElement = React.cloneElement(component, {
    id,
    element,
    module,
    ...rest,
  });
  if (isOpen) {
    return isModal ? (
      <Dialog
        title={title}
        setShow={handleClose}
        show={isOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div
          style={{
            position: 'relative',
            minHeight: minHeight,
            maxHeight: 'fit-content',
          }}
        >
          <FormContext.Provider
            value={{
              formId: id,
              isDisabled: hasLockState ? isDisabled : false,
              handleSaveCurrentTab,
            }}
          >
            <FormProvider {...methods}>{formElement}</FormProvider>
          </FormContext.Provider>
          <FormButtons
            id={id}
            hasLockState={hasLockState}
            hasSave={hasSave}
            handleClose={closeAndResetForm}
            handleSave={handleSave}
            handleSaveAndClose={handleSaveAndClose}
            isLoading={isLoading}
            handleLockStatus={hanldeLockStatus}
            handleUnlock={handleUnlock}
            isDisabled={isDisabled}
          />
        </div>
      </Dialog>
    ) : (
      <div className={`form outline-soft-primary`}>
        <FormContext.Provider
          value={{
            formId: id,
            isDisabled: hasLockState ? isDisabled : false,
            handleSaveCurrentTab,
          }}
        >
          <FormProvider {...methods}>{formElement}</FormProvider>
        </FormContext.Provider>
        <FormButtons
          id={id}
          hasLockState={hasLockState}
          hasSave={hasSave}
          handleClose={closeAndResetForm}
          handleSave={handleSave}
          handleSaveAndClose={handleSaveAndClose}
          isLoading={isLoading}
          handleLockStatus={hanldeLockStatus}
          handleUnlock={handleUnlock}
          isDisabled={isDisabled}
        />
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default Form;
