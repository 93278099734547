import { TEXT } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Ragione Sociale',
    accessor: 'ragione_sociale',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'P.IVA',
    accessor: 'piva',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'C.Fiscale',
    accessor: 'codice_fiscale',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Città',
    accessor: 'localita',
    sort: true,
    filterType: TEXT,
  },
];

const remainingColumns: ColumnCustomHeader[] = [
  {
    Header: 'CAP',
    accessor: 'cap',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Provincia',
    accessor: 'provincia',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Indirizzo',
    accessor: 'indirizzo',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Telefono',
    accessor: 'telefono',
    sort: true,
    filterType: TEXT,
  },
  {
    Header: 'Cellulare',
    accessor: 'cellulare',
    sort: true,
    filterType: TEXT,
  },
];

export const anagrafiche = {
  preview: baseColumns,
  complete: [...baseColumns, ...remainingColumns],
};
