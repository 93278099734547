import { MODULO_RIGHE } from './modules';
import {
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_ID,
  TabsId,
  MODULO_RIGHE_ID,
  SCHEDA_ID,
  schedeSlice,
  REFERENTE_ID,
} from '../../store';
import { anagrafiche, ColumnCustomHeader, filtri, moduli, referente, schede } from './tables';
import { GridColumns } from '@mui/x-data-grid';
export const accessor = (tabId: TabsId, isComplete = true): ColumnCustomHeader[] | GridColumns => {
  const map: Record<TabsId | string, any> = {
    [ANAGRAFICA_ID]: anagrafiche,
    [FILTRO_ID]: filtri,
    [MODULO_ID]: moduli,
    [MODULO_RIGHE_ID]: null,
    [SCHEDA_ID]: schede,
    [REFERENTE_ID]: referente,
  };
  return map[tabId] ? map[tabId][isComplete ? 'complete' : 'preview'] : map[MODULO_ID]['complete'];
};
